import * as React from "react"
import styled from "styled-components"
import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "popmotion"
import { SRLWrapper, useLightbox } from "simple-react-lightbox"

export const lightboxOptions = {
  settings: {
    autoplaySpeed: 0,
    slideAnimationType: "both",
  },
  caption: {},
  buttons: {
    showDownloadButton: false,
    showThumbnailsButton: false,
  },
  thumbnails: {},
  progressBar: {},
}

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: 350px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`
const Next = styled.div`
  right: 10px;
  position: absolute;
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  color: black;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  z-index: 2;
`
const Prev = styled.div`
  left: 10px;
  color: black;
  transform: scale(-1);
  position: absolute;
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
`

const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 720 : -720,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 720 : -720,
      opacity: 0,
    }
  },
}

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

export const ProductGallery = ({ gallery }) => {
  const [[page, direction], setPage] = useState([0, 0])
  const { openLightbox, closeLightbox } = useLightbox()

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, gallery.length, page)

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection])
  }

  const elements = React.useMemo(() => {
    return gallery.map(image => ({
      src: image,
      // caption: 'Lorem ipsum dolor sit amet',
      // width: 1920,
      height: "auto",
    }))
  }, [gallery])

  return (
    <SRLWrapper options={lightboxOptions} elements={elements}>
      <Container>
        <AnimatePresence initial={false} custom={direction}>
          <motion.img
            key={page}
            src={gallery[imageIndex]}
            custom={direction}
            onClick={() => openLightbox(imageIndex)}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            style={{
              position: "absolute",
              maxWidth: "100%",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x)

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1)
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1)
              }
            }}
          />
        </AnimatePresence>

        {gallery.length > 1 ? (
          <>
            <Next className="next" onClick={() => paginate(1)}>
              {"‣"}
            </Next>
            <Prev className="prev" onClick={() => paginate(-1)}>
              {"‣"}
            </Prev>
          </>
        ) : (
          []
        )}
      </Container>
    </SRLWrapper>
  )
}
