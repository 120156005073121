import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { OutlineButton } from "../../styles/buttons"
import { Flex, Block } from "../layout/shared"
import Spacer from "../spacer"
import { H1 } from "../../styles/typography"
import { useSubtotal, useTotal, useDiscount } from "../../lib/hooks"
import Image from "next/image"
import Loader from "react-loader-spinner"
import { colors } from "../../styles/colors"
import Link from "next/link"
import { displayConciergeFee, sluggedName, toCurrency } from "lib/utils"
import { useGlobalDisptach } from "context/GlobalContextProvider"
import * as actions from "../../context/actionTypes"
import { FaRegQuestionCircle } from "react-icons/fa"
import { Tooltip, withStyles } from "@material-ui/core"
import usePricing from "custom-hooks/usePricing"
import { useLocalStorage } from "react-use"
import { BsArrowLeft } from "react-icons/bs"
import { KOS_COMPANY_ID } from "lib/constants"

export const DAYTIME_DELIVERY = "daytimeDelivery"
export const EVENING_DELIVERY = "eveningDelivery"

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "white",
    fontFamily: "PhantomSans",
    border: `1px solid ${colors.base}`,
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}))(Tooltip)

export default function CartHeader({
  handleDiscountCodeClicked,
  shoppingCart,
  ...otherProps
}) {
  const dispatch = useGlobalDisptach()
  const { numberToCurrency } = usePricing()

  const {
    address,
    deliveryFee,
    deliveryWindows,
    discount,
    hideDeliveryFee,
    id: storeId,
    loadingDeliveryFee,
    logo,
    name: storeName,
    pickupMethod,
    customCheckoutEnabled,
    marketCompany,
    doesCurbside,
    doesDelivery,
    doesWalkin,
  } = otherProps

  const subtotalHook = useSubtotal(shoppingCart)
  const [subtotal, setSubtotal] = useState(subtotalHook)
  const [total, setTotal] = useState(0)
  const [appliedDiscount, setAppliedDiscount] = useState(0)
  const appliedDiscountHook = useDiscount(subtotal, discount)
  const [pricingModal, setPricingModal] = useState(false)

  const totalHook = useTotal(
    subtotal,
    pickupMethod === "Delivery" ? deliveryFee : 0,
    appliedDiscount,
    marketCompany
  )
  // Custom hooks
  const [hasSeenPricingAnnouncement, setHasSeenAnnouncement] = useLocalStorage(
    "seenPricingAnnouncement",
    false
  )

  // Mark announcement as seen
  useEffect(() => {
    return () => {
      setHasSeenAnnouncement(true)
    }
  }, [hasSeenPricingAnnouncement])

  useEffect(() => {
    setSubtotal(subtotalHook)
  }, [subtotalHook, discount])

  useEffect(() => {
    setAppliedDiscount(appliedDiscountHook)
  }, [appliedDiscountHook])

  useEffect(() => {
    setTotal(totalHook)
  }, [totalHook, discount, deliveryFee, marketCompany])
  const handleSelectTimeWindow = window => {
    dispatch({
      type: actions.SET_TIME_WINDOW,
      item: window,
    })
  }

  return (
    <Container style={{ marginBottom: 15 }}>
      <H1>Your Cart</H1>
      <Flex
        style={{
          border: "1px solid #eee",
          borderRadius: 10,
          paddingLeft: 15,
          paddingRight: 15,
          background: storeId === KOS_COMPANY_ID ? colors.kosBG : "white",
        }}
        direction="column"
        align="flex-start"
      >
        <Spacer size={30} />
        <Flex direction="row" align="center" gap="10">
          <Block>
            {!customCheckoutEnabled && (
              <Image
                src={logo}
                width={70}
                height={70}
                objectFit="cover"
                alt="company logo"
              />
            )}
          </Block>
          <Block>
            <Link href={`/store/${sluggedName(storeName)}`}>
              <StoreName light={storeId === KOS_COMPANY_ID}>
                <BsArrowLeft style={{ marginBottom: 4 }} size={20} />{" "}
                {storeName}
              </StoreName>
            </Link>
            {address?.address && (
              <>
                <Location light={storeId === KOS_COMPANY_ID}>
                  <span>{address?.address}</span>
                </Location>
              </>
            )}
          </Block>
        </Flex>
        <Spacer size={10} />
        <Flex direction="row" align="center">
          {doesCurbside && !marketCompany && (
            <BorderedButton light={storeId === KOS_COMPANY_ID}>
              CURBSIDE
            </BorderedButton>
          )}
          {doesDelivery && (
            <BorderedButton light={storeId === KOS_COMPANY_ID}>
              DELIVERY
            </BorderedButton>
          )}
          {doesWalkin && !marketCompany && (
            <BorderedButton light={storeId === KOS_COMPANY_ID}>
              IN-STORE
            </BorderedButton>
          )}
        </Flex>
        <Spacer size={36} />
        {discount.value == 0 ? (
          <EnterDiscountCodeButton
            light={storeId === KOS_COMPANY_ID}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => handleDiscountCodeClicked()}
          >
            Add discount code
          </EnterDiscountCodeButton>
        ) : (
          <SmallText>
            Discount Applied: -${discount?.value.toFixed(2)}
          </SmallText>
        )}
        <Spacer size={35} />
        {marketCompany && (
          <>
            <Spacer size={10} />
            <SubTotalText
              light={storeId === KOS_COMPANY_ID}
              style={{ minWidth: "5.5rem" }}
            >
              Concierge Fee: {displayConciergeFee(subtotal)}{" "}
              <LightTooltip
                title="This fee covers order processing by the on-the-ground Unqueue team."
                placement="right"
                style={{ fontSize: 15 }}
                enterTouchDelay={0}
              >
                <span>
                  <FaRegQuestionCircle
                    style={{ marginLeft: 5, marginBottom: 5 }}
                  />
                </span>
              </LightTooltip>
            </SubTotalText>
          </>
        )}

        <Flex align="center" direction="row" gap="32">
          <SubTotalText
            light={storeId === KOS_COMPANY_ID}
            style={{ minWidth: "5.5rem" }}
          >
            Subtotal:
          </SubTotalText>
          <SubTotalText light={storeId === KOS_COMPANY_ID}>
            {numberToCurrency(subtotal)}
          </SubTotalText>
        </Flex>
        <Spacer size={10} />
        {pickupMethod === "Delivery" && (
          <>
            <Flex align="center" direction="row" gap="32">
              <SubTotalText
                light={storeId === KOS_COMPANY_ID}
                style={{ minWidth: "5.5rem" }}
              >
                Delivery
              </SubTotalText>
              <SubTotalText light={storeId === KOS_COMPANY_ID}>
                <Flex direction="row" align="center">
                  {" "}
                  {hideDeliveryFee
                    ? "-"
                    : `${numberToCurrency(deliveryFee)}`}{" "}
                  {loadingDeliveryFee && <DeliveryFeeLoader />}
                </Flex>
              </SubTotalText>
            </Flex>
            <Spacer size={10} />
          </>
        )}
        <Flex align="center" direction="row" gap="32">
          <TotalText
            light={storeId === KOS_COMPANY_ID}
            style={{ minWidth: "5.5rem" }}
          >
            Total:
          </TotalText>
          <TotalText light={storeId === KOS_COMPANY_ID}>
            {numberToCurrency(total)}
          </TotalText>
        </Flex>
        <Spacer size={30} />
      </Flex>
    </Container>
  )
}

const Container = styled.div`
  position: sticky;
  top: 115px;
  align-self: flex-start;
  order: 0;

  @media (max-width: 700px) {
    border-bottom: 1px solid #ddd;
    position: relative;
    top: unset;
    order: 2;
  }
`
const SmallText = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: #00c569;
`
const BorderedButton = styled.div`
  border: 1px solid #b59797;
  line-height: 1;
  font-size: 16px;
  color: #21262e;
  color: ${props => (props.light ? "#fff" : "#21262e")};
  border-radius: 2px;
  padding: 2px 7px;
  margin-right: 10px;
`
const EnterDiscountCodeButton = styled(OutlineButton)`
  border: 1px solid #707070;
  font-size: 20px;
  color: #21262e;
  color: ${props => (props.light ? "#fff" : "#21262e")};
  padding: 5px 15px;
`
const SubTotalText = styled.p`
  font-size: 18px;
  color: #21262e;
  color: ${props => (props.light ? "#fff" : "#21262e")};
  line-height: 1.1;
  font-weight: 400;
  margin-bottom: 0;
`
const TotalText = styled.p`
  font-size: 26px;
  color: #22252d;
  color: ${props => (props.light ? "#fff" : "#22252d")};
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: 0;
`
export const CartStoreLogo = styled.img`
  width: 70px;
  height: 70px;
  background: #ddd;
`
export const StoreName = styled.h3`
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  color: ${props => (props.light ? "#fff" : "#22252d")};
  text-decoration: underline;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`
export const Location = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${props => (props.light ? "#fff" : "#21262e")};
  margin-bottom: 5px;
`
export const DiscountCode = styled.button`
  display: block;
  border: 2px solid #21262e;
  border-radius: 5px;
  color: #21262e;
  color: ${props => (props.light ? "#fff" : "#21262e")};
  width: 200px;
  height: 40px;
  background-color: transparent;
`
export const LineItem = styled.p`
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  color: #22252d;
  color: ${props => (props.light ? "#fff" : "#21262e")};
`
const NextDeliveryDay = styled.p`
  font-size: 18px;
  max-width: 400px;
  line-height: 1.3;
  margin-bottom: 10px;
`

const DeliveryFeeLoader = () => (
  <Loader
    style={{ marginLeft: 10 }}
    height={15}
    width={15}
    color={colors.base}
    type="Oval"
  />
)
