import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Checkbox from "@material-ui/core/Checkbox"

const objToArray = obj => {
  if (!obj) return []
  let arr = []
  let keys = Object.keys(obj)
  for (const key of keys) {
    arr.push({ id: key, ...obj[key], price: parseFloat(obj[key].price) })
  }
  return arr
}

const sortAscending = (a, b) => a.order - b.order

const getRestrictionsMessage = (variation, selectedVariations) => {
  if (variation.minimumToSelect === 0 && variation.maximumToSelect === 0) return
  //let selectedVariationIndex = selectedVariations.findIndex(v=> v.id === variation.id)
  if (variation.minimumToSelect === 1 && variation.maximumToSelect === 1)
    return `Select one`
  if (variation.minimumToSelect > 0 && variation.maximumToSelect > 0)
    return `Select at least ${variation.minimumToSelect} and up to ${variation.maximumToSelect}`
  if (variation.minimumToSelect > 0)
    return `Select at least ${variation.minimumToSelect}`
  if (variation.maximumToSelect > 0)
    return `Select up to ${variation.maximumToSelect}`
}

function StoreProductVariations({
  variations,
  selectedVariations,
  setSelectedVariations,
}) {
  const [selectedIDList, setSelectedIDList] = useState([])
  const handleOptionSelected = (variation, option, type) => {
    const variationExists = selectedVariations.findIndex(
      el => el.id === variation.id
    )
    let tempSelectedVariations = [...selectedVariations]

    //if only one can be selected change replace selected option
    if (type === "radio") {
      if (variationExists != -1) {
        tempSelectedVariations[variationExists] = {
          ...tempSelectedVariations[variationExists],
          selectedOptions: [option],
        }
      } else {
        tempSelectedVariations.push({
          ...variation,
          selectedOptions: [option],
        })
      }
    }

    //if multiple can be selected ensure that it complies with the min max settings
    if (type === "checkbox") {
      if (variationExists != -1) {
        let selectedOptions =
          tempSelectedVariations[variationExists].selectedOptions ?? []
        let optionExists = selectedOptions.findIndex(el => el.id == option.id)
        if (
          optionExists == -1 &&
          selectedOptions.length == variation.maximumToSelect &&
          variation.maximumToSelect != 0
        )
          return // selected to many options
        tempSelectedVariations[variationExists] = {
          ...tempSelectedVariations[variationExists],
          selectedOptions:
            optionExists === -1
              ? [...selectedOptions, option]
              : [...selectedOptions.filter(el => el.id != option.id)],
        }
      } else {
        tempSelectedVariations.push({
          ...variation,
          selectedOptions: [option],
        })
      }
    }
    // Set selectedVariations
    setSelectedVariations([...tempSelectedVariations])
  }

  useEffect(() => {
    let arr = []
    for (const variation of selectedVariations) {
      for (const option of variation?.selectedOptions) {
        arr.push(option.id)
      }
    }
    setSelectedIDList([...arr])
    // onChange(selectedVariations)
  }, [selectedVariations])

  return (
    <div>
      {variations.sort(sortAscending).map(variation => {
        let options = objToArray(variation.options)
        {
          /* Only show the title if there are visible and active variations */
        }
        if (
          objToArray(variation?.options).filter(el => el.active).length === 0
        ) {
          return
        }
        return (
          <VariationContainer key={variation.id}>
            <Title>{variation.title}</Title>
            <Restrictions>{getRestrictionsMessage(variation)}</Restrictions>
            {variation.maximumToSelect === 1 &&
            variation.minimumToSelect === 1 ? (
              <div onChange={e => console.log(e.target.value)}>
                {options
                  .sort(sortAscending)
                  .filter(option => option?.active !== false)
                  .map(option => (
                    <OptionContainer key={option.id}>
                      <Option>
                        <RadioButton
                          onChange={() =>
                            handleOptionSelected(variation, option, "radio")
                          }
                        >
                          <input
                            type="radio"
                            value={option.name}
                            name={variation.title}
                            checked={selectedIDList.includes(option.id)}
                            onChange={() => {}}
                          />
                          <span>{option.name}</span>
                        </RadioButton>
                      </Option>
                      <Price>
                        <span>{`$${parseFloat(option?.price).toFixed(
                          2
                        )}`}</span>
                      </Price>
                    </OptionContainer>
                  ))}
              </div>
            ) : (
              <div>
                {options
                  .sort(sortAscending)
                  .filter(option => option?.active !== false)
                  .map(option => (
                    <OptionContainer key={option.id}>
                      <Option>
                        <Checkbox
                          label={`${option.name} ($${parseFloat(
                            option.price
                          ).toFixed(2)})`}
                          onChange={() =>
                            handleOptionSelected(variation, option, "checkbox")
                          }
                          checked={selectedIDList.includes(option.id)}
                        />
                        {`${option.name} `}
                      </Option>
                      <Price>{`$${parseFloat(option.price).toFixed(2)}`}</Price>
                    </OptionContainer>
                  ))}
              </div>
            )}
          </VariationContainer>
        )
      })}
    </div>
  )
}

export default StoreProductVariations

const VariationContainer = styled.div`
  margin-bottom: 20px;
`

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
`

const Option = styled.div``

const Price = styled.div`
  opacity: 0.6;
`
const Restrictions = styled.div`
  color: #21262e;
  opacity: 0.6;
`

const Title = styled.h3`
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 1em;
`

const RadioButton = styled.label`
  display: inline-block;
  position: relative;
  height: 24px;
  width: 24px;
  &:hover {
    cursor: pointer;
  }
  input {
    position: absolute;
    opacity: 0;
  }
  span {
    position: absolute;
    left: 40px;
    top: 10px;
    transition: all 0.3s;
    width: max-content;
  }

  span:before {
    transition: all 0.3s;
    left: -28px;
    top: 0px;
    position: absolute;
    opacity: 1;
    content: " ";
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid #707070;
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  input:checked ~ span:before {
    border: 1px solid #f50057;
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      #f50057 0%,
      #f50057 40%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`
