import React from "react"
import styled from "styled-components"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { makeStyles } from "@material-ui/core"

const generateItemLabel = ({ name, price }) => {
  let label = name

  if (price == 0) return label

  return (label = label + ` ($${price.toFixed(2)})`)
}

const useStyles = makeStyles({
  label: {
    fontFamily: "PhantomSans",
    fontWeight: 400,
    fontSize: 18,
  },
})

const Addon = ({ title, items = [], selectedAddons, onAddonClick }) => {
  const classes = useStyles()

  if (items.length === 0) return <></>

  return (
    <Container>
      <Title>{title}</Title>
      <Items>
        {items.map((item, i) => (
          <CheckBoxWrapper key={i + item.name}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedAddons.indexOf(item) !== -1}
                  onChange={e => onAddonClick(item, e)}
                  name={item.name}
                />
              }
              label={generateItemLabel(item)}
              classes={{
                label: classes.label,
              }}
            />
          </CheckBoxWrapper>
        ))}
      </Items>
    </Container>
  )
}

export default Addon

const Container = styled.div`
  margin: 10px 0;
`

const Title = styled.h3`
  color: #21262e;
  opacity: 0.6;
  font-weight: 400;
  margin-bottom: 5px;
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* gap: 40px; */
`

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`
