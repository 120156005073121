import React from 'react'

const useCartPackTime = (cart) => {

    //Get pack times
    const packTimes = cart?.filter(el => el.packingTime).map(el => el.packingTime)
    
    if (!packTimes.length) {
        return {
            packTime: 15,
            timeUnit: "minutes",
            timeValue: "15"
        } //Default pack time 
    }

    const sortedPackTimes = packTimes.sort((a, b) => a.packTime < b.packTime)
    return sortedPackTimes[0]
}

export default useCartPackTime