import React, { useState } from "react"
import styled from "styled-components"
import Link from "next/link"
import { colors } from "../styles/colors"
import { useGlobalState } from "../context/GlobalContextProvider"
import { KOS_COMPANY_ID } from "lib/constants"
import { isSSR } from "lib/utils"
import { Flex } from "./layout/shared"
import { TextInput } from "@mantine/core"
import { BiSearchAlt } from "react-icons/bi"
import { UnstyledButton } from "styles/buttons"
import { useMedia } from "react-use"

const CustomHeader = ({
  backgroundColor,
  logo,
  textColor = "white",
  onClick,
}) => {
  const state = useGlobalState()
  const id = state?.currentStoreDetails?.id ?? null
  // const pool = state?.currentStoreDetails?.pool ?? 'prod' //Hide Cart from stores that aren't part of our checkout testing.
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Nav>
        <Link href={`/store/${state?.currentStoreDetails?.storeSlug}`}>
          <LogoImg src={logo} />
        </Link>
        <Flex>
          {onClick && <FauxSearch onClick={onClick} />}
          <Cart color={textColor}>
            {!isSSR && (
              <Link href="/cart">
                <a style={{ color: textColor }}>
                  Cart ({state.shoppingCart.length})
                </a>
              </Link>
            )}
          </Cart>
        </Flex>
      </Nav>
    </Wrapper>
  )
}

export default CustomHeader

export const FauxSearch = ({ onClick }) => {
  const isMobile = useMedia("(max-width: 530px)")

  return (
    <UnstyledButton
      onClick={onClick}
      style={{
        marginRight: 16,
      }}
    >
      <TextInput
        styles={{
          root: { width: !isMobile ? "100%" : "130px" },
          input: {
            fontFamily: "PhantomSans",
            fontSize: "1.1rem",
            cursor: "pointer",
            pointerEvents: "none",
          },
        }}
        radius="xl"
        placeholder={isMobile ? "Search" : "Search for anything"}
        icon={<BiSearchAlt size={20} />}
      />
    </UnstyledButton>
  )
}

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  background: ${props => props.backgroundColor || colors.base};
  z-index: 99;
`
const Nav = styled.nav`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 51px;

  @media (max-width: 550px) {
    height: 50px;
  }
`

const LogoImg = styled.img`
  cursor: pointer;
  width: 80px;
  height: 80px;
  position: relative;
  object-fit: cover;
  border: 2px solid #eee;
  border-radius: 5px;
  top: 30px;
  background-color: #7070704d;
  color: #d61010;
  box-shadow: 0 0.4px 1.1px rgba(0, 0, 0, 0.009),
    0 1px 3.9px rgba(0, 0, 0, 0.013), 0 1.9px 10.4px rgba(0, 0, 0, 0.017),
    0 4px 25.5px rgba(0, 0, 0, 0.021), 0 11px 80px rgba(0, 0, 0, 0.03);

  @media (max-width: 550px) {
    height: 70px;
    width: 70px;
    top: 20px;
  }

  /* @media (max-width: 575.98px) {
    width: 60px;
    height: 50px;
    left: 20px;
    top: 30px;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    left: 35px;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    left: 30px;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    left: 30px;
  } */
`

const Cart = styled.div`
  display: block;
  align-self: center;
  justify-self: flex-end;
  margin-right: 20px;
  font-size: 20px;
  color: ${props => props.color || "white"} > a {
    color: ${props => props.color || "white"};
  }
`
