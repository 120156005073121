import styled from "styled-components"

export const H1 = styled.h1`
  font-size: 65px;
  font-weight: 300;

  @media (max-width: 700px) {
    display: none;
  }
`
export const H2 = styled.h2`
  font-size: 40px !important ;
  font-weight: 300;
`
export const BodyText = styled.p`
  font-size: 16px;
`
