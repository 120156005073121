import React from "react"
import styled from "styled-components"
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"
// import components
const Input = styled.input`
  border-radius: 2px;
  border: 2px solid #cfcfcf;
  color: #22252d;
  width: auto;
  width: 40px;
  text-align: center;
  background: none;
  border: none;
  font-weight: 400;
  font-size: 20px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
`

const Counter = ({ count, setCount = () => {} }) => {
  return (
    <Container>
      <CountButton
        type="button"
        onClick={() => setCount(count !== 1 ? count - 1 : count)}
      >
        <AiOutlineMinus size={16} />
      </CountButton>
      <Input
        size="5"
        type="text"
        value={count}
        onChange={e => setCount(Number(e.target.value))}
      />
      <CountButton type="button" onClick={() => setCount(count + 1)}>
        <AiOutlinePlus size={16} />
      </CountButton>
    </Container>
  )
}

export default Counter

const CountButton = styled.button`
  height: 42px;
  font-size: 24px;
  border-radius: 20px;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  width: 42px;
  background: #eee;
`
