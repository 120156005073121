import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import Counter from "../shared/counter"
import Addon from "./storeAddOn"
import closeButtonIcon from "../../public/close_button_icon.svg"
import { Dialog, DialogActions, DialogContent } from "@material-ui/core"
import Spacer from "../spacer"
import { MainButton } from "../../styles/buttons"
import { useMedia } from "react-use"
import LOGGER from "../../lib/logger"
import { useGlobalState } from "context/GlobalContextProvider"
import { useStoreOpen } from "lib/hooks"
import { getStoreOpeningAndClosing, getTodaysHours } from "lib/utils"
import AppStoreLinks from "components/appStoreLinks/appStoreLinks"
import { TRACKING_EVENTS, _trackEvent } from "lib/mixpanel"
import { Flex } from "components/layout/shared"
import StoreProductVariations from "./storeProductVariations"
import Skeleton from "react-loading-skeleton"
import { ProductGallery } from "./product-gallery"
import moment from "moment"
import usePricing from "custom-hooks/usePricing"
import { Button, Typography } from "@unqueue-dev-inc/unqueue-ui-web"
import useCartPackTime from "custom-hooks/use-cart-pack-time"
import { Alert, SegmentedControl } from "@mantine/core"
import { BsInfoCircle } from "react-icons/bs"
import { KOS_COMPANY_ID, NAMDEVCO_COMPANY_ID } from "lib/constants"
import toast from "react-hot-toast"
import { LightTooltip } from "components/cart/cart-header"
import { FaRegQuestionCircle } from "react-icons/fa"

const FILENAME = "storeProductDetailModal.js"
const freeAddonsLimit = limit => {
  if (!limit || limit == "999") return ""
  else if (limit) return `(Select up to: ${limit})`
}

const getTotalPrice = (productPrice, addons, count) => {
  let price = productPrice + totalAddonsPrice(addons)
  return getFormattedPrice(price * (count ? count : 1))
}

const StoreProductDetailModal = ({
  checkoutEnabled,
  editing = false,
  handleAddToCart,
  handleOnClose,
  open,
  product,
  storeId,
  photoGallery,
  variations,
  loadingVariations,
  handleWaitListRegistration,
  ...addons
}) => {
  const METHODNAME = "StoreProductDetailModal"

  // LOGGER.Debug(FILENAME, METHODNAME, { product, addons, variations })
  const { freeAddons, premiumAddons } = addons
  const [count, setCount] = useState(1)
  const [selectedAddons, setSelectedAddons] = useState([])
  const [disableAddToCart, setDisableAddToCart] = useState(false)
  const [hasRequiredVariations, setHasRequiredVariations] = useState(false)
  const [selectedVariations, setSelectedVariations] = useState(
    product.variations ? [...product.variations] : []
  )
  const [variationTotal, setVariationTotal] = useState(0)
  const [gallery, setGallery] = useState([
    {
      thumbnail: product.image,
      original: product.image,
    },
  ]) //Default to using the product image as the main gallery image while the others load

  const { currentStoreDetails, shoppingCart } = useGlobalState()
  const {
    // numberToCurrency,
    currentCurrency: globalCurrentCurrency,
    currencyCodes,
  } = usePricing()
  const [currentCurrency, setCurrency] = useState(globalCurrentCurrency)

  const { openingDays } = currentStoreDetails
  const todayHours = getTodaysHours(openingDays)

  const preSaleProduct = shoppingCart.find(el => el.isPreSale)
  const cartPackTime = useCartPackTime(shoppingCart)

  const isMobile = useMedia("(max-width: 648px)")
  const { isOpen } = useStoreOpen(
    openingDays,
    todayHours,
    getStoreOpeningAndClosing(
      todayHours.openingTimeObj,
      todayHours.closingTimeObj
    )
  )

  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currentCurrency,
  })
  const numberToCurrency = price => {
    return `${formatter.format(price)}${
      currentCurrency === currencyCodes.USD ? " USD" : ""
    }`
    // return formatter.format(price).replace(/(\.|,)00$/g, "")
  }
  //Clear gallery if the product does not have an image
  useEffect(() => {
    if (!product.image) {
      setGallery([])
    }
  }, [product])

  useEffect(() => {
    if (!product.inStock) setDisableAddToCart(true)

    if (!openingDays) return

    if (!isOpen && !currentStoreDetails.acceptsOrdersAfterHours)
      setDisableAddToCart(true)
  }, [product, currentStoreDetails, isOpen])

  useEffect(() => {
    if (photoGallery.length) {
      setGallery(photoGallery)
    }
  }, [photoGallery])

  const availableDays = useMemo(() => {
    if (product?.categoryAvailableDays) {
      return product.categoryAvailableDays.filter(day => day.available)
    }
    return []
  }, [product])

  const onAddonClick = (addon, e) => {
    if (!e.target.checked) return removeAddon(addon)

    if (addon.type === "Basic") {
      if (
        selectedAddons.filter(item => item.type === "Basic").length <
        product.maxFreeAddons
      ) {
        addon.applyPrice = false
      } else {
        // Replace with toast message
        return alert("You've reached the add-on limit")
      }
    } else {
      addon.applyPrice = true
    }
    setSelectedAddons([...selectedAddons, addon])
  }

  const removeAddon = addon => {
    let addons = [...selectedAddons]
    let index = addons.indexOf(addon)
    addons.splice(index, 1)
    setSelectedAddons([...addons])
  }

  const addToCart = ({ saveAsNew = false }) => {
    let productDetails = { ...product }

    // if saving as new cartItem then remove the cartId
    if (saveAsNew && productDetails.cartItemId) delete productDetails.cartItemId
    const newCartItem = {
      ...productDetails,
      count,
      addons: selectedAddons,
      variations: selectedVariations,
    }
    setSelectedAddons([])
    handleAddToCart(newCartItem)
    _trackEvent(TRACKING_EVENTS.PRODUCT_ADDED_TO_CART, {
      title: newCartItem.title,
      price: newCartItem.salePrice ? newCartItem.salePrice : newCartItem.price,
      store: currentStoreDetails.name,
    })
    handleOnClose()
  }

  const handleSelectedVariationsChanged = selectedVariationsFromComponent => {
    setSelectedVariations(selectedVariationsFromComponent)
  }

  useEffect(() => {
    let total = 0
    for (const variation of selectedVariations) {
      for (const option of variation.selectedOptions) {
        total += option.price
      }
    }

    setVariationTotal(total)
  }, [selectedVariations])

  // ensure check if manditory variations have been selected
  useEffect(() => {
    let flag = true

    let mustExist = variations
      .map(variation => {
        if (variation.minimumToSelect > 0)
          return { id: variation.id, count: variation.minimumToSelect }
      })
      .filter(el => el != undefined)

    for (const variation of mustExist) {
      let exists = selectedVariations.findIndex(
        v => v.id == variation.id && v.selectedOptions.length >= variation.count
      )
      if (exists === -1) flag = false
    }

    setHasRequiredVariations(flag)
  }, [selectedVariations])

  // For KOS, you cannot reserve more than 5 copies of the book
  useEffect(() => {
    if (count > 5 && storeId === KOS_COMPANY_ID) {
      setCount(5)
      toast("You cannot reserve more than 5 copies of the book", {
        id: "reserve-limit",
      })
    }
  }, [count, storeId])

  return (
    <Dialog
      open={open}
      onClose={() => {
        setGallery([])
        handleOnClose()
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      fullWidth={true}
      fullScreen={isMobile}
      maxWidth="xs"
      repositionOnUpdate={false}
    >
      <Close>
        <CloseButton onClick={() => handleOnClose(false)}>
          <img src={closeButtonIcon} />
        </CloseButton>
      </Close>
      <DialogContent
        style={{ padding: isMobile ? "20px 15px 75px" : "25px 25px" }}
      >
        <Grid>
          {gallery.length ? (
            <ProductGallery
              gallery={gallery.map(gallery => gallery.original)}
            />
          ) : null}
          <Details>
            <Title>{product.title}</Title>
            {/* Let the user preview these prices in currencies
            available */}
            <PriceBox>
              <Price onSale={product.onSale ? true : undefined}>
                {numberToCurrency(
                  ((product.pricing && currentCurrency
                    ? product.pricing[currentCurrency]
                    : product.price) +
                    variationTotal) *
                    count
                )}
                {storeId === KOS_COMPANY_ID && (
                  <LightTooltip
                    title={`This price is for display purposes only. Your card will be charged in ${globalCurrentCurrency}.`}
                    placement="right"
                    style={{ fontSize: 15 }}
                    enterTouchDelay={0}
                  >
                    <span>
                      <FaRegQuestionCircle
                        style={{ marginLeft: 5, marginBottom: 5 }}
                      />
                    </span>
                  </LightTooltip>
                )}
              </Price>
              {product.onSale ? (
                <SalePrice>
                  {numberToCurrency(
                    (product.salePrice + variationTotal) * count
                  )}
                </SalePrice>
              ) : null}
            </PriceBox>
            {storeId === KOS_COMPANY_ID && (
              <>
                <Flex direction="column">
                  <h3 style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                    Preview in another currency:
                  </h3>
                  <SegmentedControl
                    size="sm"
                    styles={{
                      label: {
                        marginBottom: 0,
                      },
                    }}
                    onChange={currency => {
                      setCurrency(currency)
                    }}
                    value={currentCurrency}
                    data={[
                      { label: "🇺🇸 USD", value: currencyCodes.USD },
                      { label: "🇹🇹 TTD", value: currencyCodes.TTD },
                      { label: "🇬🇧 GBP", value: currencyCodes.GBP },
                      { label: "🇪🇺 EUR", value: currencyCodes.EUR },
                    ]}
                  />
                </Flex>
                <Spacer size={10} />
              </>
            )}
            <Description>{product.description}</Description>
            {product.packingTime && !product.isWaitListProduct && (
              <>
                <Spacer size={3} />
                <PackTime>
                  Please allow up to {product.packingTime.timeValue}{" "}
                  {product.packingTime.timeUnit} for preparation.
                </PackTime>
              </>
            )}
            {product.inStock ? null : (
              <>
                <Spacer size={20} />
                <SalePrice>Out of Stock</SalePrice>
              </>
            )}
            {!product?.isPreSale ? null : (
              <>
                <Spacer size={10} />
                <Alert
                  icon={<BsInfoCircle size={16} />}
                  title="This is a pre-sale item"
                  color="lime"
                  radius="lg"
                  styles={{ title: { fontSize: "1rem", fontWeight: 500 } }}
                  style={{ fontFamily: "PhantomSans" }}
                >
                  <Typography style={{ fontSize: "1rem" }} variant="body">
                    This pre-order item has an estimated delivery date of{" "}
                    {moment(product.preSaleDate).format("Do MMMM, YYYY")}.
                    You’ll receive a notification the day before delivery.
                  </Typography>
                </Alert>
              </>
            )}
            {/* Let the user know that their cart has an item with a really long (more than 14 days) */}
            {/* pack time. Only show this message in the same store that the cart belongs to */}
            {cartPackTime &&
              cartPackTime?.packTime > 20160 &&
              shoppingCart[0]?.storeId === currentStoreDetails.id && (
                <>
                  <Spacer size={10} />
                  <Alert
                    icon={<BsInfoCircle size={16} />}
                    title=""
                    color="orange"
                    radius="lg"
                    style={{ fontFamily: "PhantomSans" }}
                  >
                    <Typography style={{ fontSize: "1rem" }} variant="body">
                      Your cart contains an item with a pack time of{" "}
                      {cartPackTime.timeValue} {cartPackTime.timeUnit}. This
                      pack time has been applied to your entire cart. If you’d
                      like to get this item before then, please check it out
                      separately.
                    </Typography>
                  </Alert>
                </>
              )}
            {!product.isPreSale &&
              !product.isWaitListProduct &&
              preSaleProduct &&
              shoppingCart[0]?.storeId === currentStoreDetails.id && (
                <>
                  <Spacer size={10} />
                  <Alert
                    icon={<BsInfoCircle size={16} />}
                    title=""
                    color="orange"
                    radius="lg"
                    style={{ fontFamily: "PhantomSans" }}
                  >
                    <Typography style={{ fontSize: "1rem" }} variant="body">
                      Your cart contains a pre-order item with an estimated
                      delivery date of{" "}
                      {moment(preSaleProduct.preSaleDate).format(
                        "Do MMMM, YYYY"
                      )}
                      . This date has been applied to your entire cart. If you’d
                      like to get this item before then, please check it out
                      separately.
                    </Typography>
                  </Alert>
                </>
              )}
            {availableDays.length && availableDays.length !== 7 ? (
              <AvailableDays>
                Available on{" "}
                {availableDays.map(
                  (day, i) =>
                    `${day.day}s${
                      i === availableDays.length - 2
                        ? " and "
                        : i === availableDays.length - 1
                        ? "."
                        : ", "
                    }`
                )}
              </AvailableDays>
            ) : (
              []
            )}
            <Spacer size={24} />
            {checkoutEnabled ? (
              <>
                {loadingVariations && (
                  <>
                    <Skeleton
                      height={20}
                      width={50}
                      style={{ marginBottom: 15 }}
                    />
                    <Skeleton
                      height={20}
                      style={{ marginBottom: 10 }}
                      count={4}
                    />
                  </>
                )}
                {variations.length > 0 && (
                  <OptionsBox>
                    <StoreProductVariations
                      variations={variations}
                      selectedVariations={selectedVariations}
                      setSelectedVariations={setSelectedVariations}
                      onChange={handleSelectedVariationsChanged}
                    />
                  </OptionsBox>
                )}
                {(freeAddons.length || premiumAddons.length) &&
                !variations.length ? (
                  <OptionsBox>
                    <Addon
                      title={`Basic Addons ${freeAddonsLimit(
                        product.maxFreeAddons
                      )}`}
                      items={freeAddons}
                      selectedAddons={selectedAddons}
                      onAddonClick={onAddonClick}
                    />
                    <Spacer size={10} />
                    <Addon
                      title="Premium Addons"
                      items={premiumAddons}
                      selectedAddons={selectedAddons}
                      onAddonClick={onAddonClick}
                    />
                  </OptionsBox>
                ) : (
                  []
                )}
              </>
            ) : (
              <AppStoreContainer>
                <AppStoreLinks />
              </AppStoreContainer>
            )}
          </Details>
        </Grid>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
        {checkoutEnabled ? (
          <FooterBox noImage={!product.image}>
            {/* Store is not currently accepting sales. Show a note */}
            {/* Waitlist product, cannot be immediately purchased */}
            {disableAddToCart && storeId === NAMDEVCO_COMPANY_ID ? (
              <>
                <Alert
                  color="red"
                  icon={<BsInfoCircle />}
                  styles={{
                    title: {
                      fontFamily: "PhantomSans",
                      fontWeight: 500,
                      fontSize: "1.1rem",
                      marginBottom: 0,
                    },
                    message: {
                      fontSize: "1rem",
                      fontFamily: "PhantomSans",
                      lineHeight: 1.2,
                    },
                  }}
                >
                  Due to weekly fluctuations of prices and availability, the
                  Unqueue NAMDEVCO Farmers&apos; Market can only accept orders
                  starting the day before the market, Friday, from 12pm, through
                  to 6am on Saturday mornings.
                </Alert>
              </>
            ) : product.isWaitListProduct ? (
              <Button onClick={() => handleWaitListRegistration(product)}>
                Reserve my book
              </Button>
            ) : (
              <>
                <Empty />
                {disableAddToCart && (
                  <Alert
                    color="red"
                    icon={<BsInfoCircle />}
                    styles={{
                      title: {
                        fontFamily: "PhantomSans",
                        fontWeight: 500,
                        fontSize: "1.1rem",
                        marginBottom: 0,
                      },
                      message: {
                        fontSize: "1rem",
                        fontFamily: "PhantomSans",
                        lineHeight: 1.2,
                      },
                    }}
                  >
                    This store is currently closed and not accepting pre-orders.
                  </Alert>
                )}
                <Flex direction="row">
                  <CounterContainer count={count} setCount={setCount} />
                  {editing && (
                    <MainButton
                      style={{ marginLeft: 10 }}
                      disabled={disableAddToCart || !hasRequiredVariations}
                      onClick={() => addToCart({ saveAsNew: false })}
                    >
                      Save
                    </MainButton>
                  )}
                  <MainButton
                    style={{ marginLeft: 10 }}
                    disabled={disableAddToCart || !hasRequiredVariations}
                    onClick={() => addToCart({ saveAsNew: true })}
                  >
                    {editing ? "Add as new item" : "Add to Cart"}
                  </MainButton>
                </Flex>
              </>
            )}
          </FooterBox>
        ) : (
          <AppStoreContainer>
            <AppStoreLinks />
          </AppStoreContainer>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default StoreProductDetailModal

const Grid = styled.div`
  display: grid;
  /* grid-template-columns: 350px minmax(0, 1fr); */
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    gap: 5px;
  }
`

const Details = styled.div``

const Title = styled.h1`
  font-size: 28px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
  color: #21262e;
  max-width: 400px;
  @media (max-width: 640px) {
    margin-top: 20px;
  }
`

const PriceBox = styled.div`
  display: flex;
  margin-top: 7px;

  > * + * {
    margin-left: 8px;
  }
`

const Price = styled.p`
  font-weight: 400;
  margin-bottom: 10px;
  text-decoration: ${({ onSale }) => (onSale ? "line-through" : "none")};
`

const PackTime = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 4px;
  text-align: left;
  width: 100%;
`

const SalePrice = styled.p`
  color: red;
  line-height: 1.2;
`

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 35px;
  width: 35px;
  transition: 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  :hover {
    background: ${props => (props.black ? "#999" : "#fafafa")};
  }
`
const CloseButton = styled.button`
  background-color: #d6371a;
  width: 35px;
  height: 35px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  z-index: 2;
`

const Description = styled.p`
  color: #707070;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  font-size: 18px;
  max-width: 500px;
  margin-bottom: 10px;
  white-space: pre-line;
`

const AvailableDays = styled.div`
  color: #0c0c0c;
  font-size: 17px;
  font-weight: 400;
  font-style: italic;
`

const OptionsBox = styled.div``
const FooterBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  padding-bottom: 10px;

  @media (max-width: 550px) {
    grid-template-columns: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const AppStoreContainer = styled.div`
  margin-top: 30px;
`
const CounterContainer = styled(Counter)`
  flex-grow: 1;
`
const Empty = styled.div`
  @media (max-width: 550px) {
    display: none;
  }
`
