import {
  useGlobalDisptach,
  useGlobalState,
} from "context/GlobalContextProvider"
import { KOS_COMPANY_ID } from "lib/constants"
import { useRouter } from "next/router"
import { useSessionStorage } from "react-use"
import * as actions from "../context/actionTypes"

const currencyCodes = {
  AFN: "AFN",
  ALL: "ALL",
  DZD: "DZD",
  AOA: "AOA",
  ARS: "ARS",
  AMD: "AMD",
  AWG: "AWG",
  AUD: "AUD",
  AZN: "AZN",
  BSD: "BSD",
  BHD: "BHD",
  BBD: "BBD",
  BDT: "BDT",
  BYR: "BYR",
  BZD: "BZD",
  BMD: "BMD",
  BTN: "BTN",
  XBT: "XBT",
  BOB: "BOB",
  BAM: "BAM",
  BWP: "BWP",
  BRL: "BRL",
  BND: "BND",
  BGN: "BGN",
  BIF: "BIF",
  XPF: "XPF",
  KHR: "KHR ",
  CAD: "CAD",
  CVE: "CVE",
  KYD: "KYD",
  FCF: "FCFA",
  CLP: "CLP",
  CLF: "CLF",
  CNY: "CNY",
  COP: "COP",
  CF: "CF",
  CDF: "CDF",
  CRC: "CRC",
  HRK: "HRK",
  CUC: "CUC",
  CZK: "CZK",
  DKK: "DKK",
  DJF: "DJF",
  DOP: "DOP",
  XCD: "XCD",
  EGP: "EGP",
  ETB: "ETB",
  FJD: "FJD",
  GMD: "GMD",
  GBP: "GBP",
  GEL: "GEL",
  GHS: "GHS",
  GTQ: "GTQ",
  GN: " GNF",
  GYD: "GYD",
  HTG: "HTG",
  HNL: "HNL",
  HKD: "HKD",
  HUF: "HUF",
  ISK: "ISK",
  INR: "INR",
  IDR: "IDR",
  IRR: "IRR",
  IQD: "IQD",
  ILS: "ILS",
  JMD: "JMD",
  JPY: "JPY",
  JOD: "JOD",
  KZT: "KZT",
  KES: "KES",
  KWD: "KWD",
  KGS: "KGS",
  LAK: "LAK",
  LBP: "LBP",
  LSL: "LSL",
  LRD: "LRD",
  LYD: "LYD",
  MOP: "MOP",
  MKD: "MKD",
  MGA: "MGA",
  MWK: "MWK",
  MYR: "MYR",
  MVR: "MVR",
  MRO: "MRO",
  MUR: "MUR",
  MXN: "MXN",
  MDL: "MDL",
  MAD: "MAD",
  MZN: "MZN",
  MMK: "MMK",
  NAD: "NAD",
  NPR: "NPR",
  ANG: "ANG",
  NZD: "NZD",
  NIO: "NIO",
  NGN: "NGN",
  NOK: "NOK",
  OMR: "OMR",
  PKR: "PKR",
  PAB: "PAB",
  PGK: "PGK",
  PYG: "PYG ",
  PHP: "PHP",
  PLN: "PLN",
  QAR: "QAR",
  RO: " RON",
  RUB: "RUB",
  RWF: "RWF",
  SVC: "SVC",
  SAR: "SAR",
  RSD: "RSD",
  SCR: "SCR",
  SLL: "SLL",
  SGD: "SGD",
  SBD: "SBD",
  SOS: "SOS",
  ZAR: "ZAR",
  KRW: "KRW",
  VES: "VES",
  LKR: "LKR",
  SDG: "SDG",
  SRD: "SRD",
  SZL: "SZL",
  SEK: "SEK",
  CHF: "CHF",
  TJS: "TJS",
  TZS: "TZS",
  THB: "THB",
  TOP: "TOP",
  TTD: "TTD",
  TND: "TND",
  TRY: "TRY",
  TMT: "TMT",
  UGX: "UGX",
  UAH: "UAH",
  AED: "AED",
  USD: "USD",
  UYU: "UYU",
  UZS: "UZS",
  VND: "VND",
  XOF: "XOF",
  YER: "YER",
  ZMW: "ZMW",
  ETH: "ETH",
  EUR: "EUR",
  LTC: "LTC",
  TWD: "TWD",
  PEN: "PEN",
}

const usePricing = () => {
  const router = useRouter()
  const state = useGlobalState()
  const dispatch = useGlobalDisptach()
  const id = state?.currentStoreDetails?.id ?? "" //Hide from stores that aren't part of our KOS testing.

  // If the store is KOS, use USD as default currency
  const [currentCurrency, setCurrentCurrency] = useSessionStorage(
    "currentCurrency",
    id === KOS_COMPANY_ID ? currencyCodes.USD : currencyCodes.TTD
  )

  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currentCurrency,
  })

  const numberToCurrency = price => {
    return `${formatter.format(price)}${
      currentCurrency === currencyCodes.USD ? " USD" : ""
    }`
    // return formatter.format(price).replace(/(\.|,)00$/g, "")
  }

  const setCurrency = currency => {
    const path = router.asPath
    if (path === "/cart" || state?.shoppingCart?.length) {
      const confirmation = window.confirm(
        "Are you sure? This will clear your cart."
      )
      if (confirmation) {
        setCurrentCurrency(currency)
        requestAnimationFrame(() => {
          dispatch({ type: actions.CLEAR_CART_ITEMS })
          // Set the currency and refresh the page for changes to take effect
          if (
            path === "/store/the-king-of-soca-store" ||
            (window && window?.location?.host.includes("kingofsoca"))
          ) {
            router.reload()
          } else {
            router.replace("/store/the-king-of-soca-store")
          }
        })
      }
      return
    }
    // Set the currency and refresh the page for changes to take effect
    setCurrentCurrency(currency)

    router.reload()
  }

  return {
    currentCurrency,
    currencyCodes,
    numberToCurrency,
    setCurrency,
  }
}

export default usePricing
