import React, { useState } from "react"
import { Dialog, SegmentedControl } from "@mantine/core"
import { Flex } from "./layout/shared"
import usePricing from "custom-hooks/usePricing"
import { useGlobalState } from "context/GlobalContextProvider"
import { KOS_COMPANY_ID } from "lib/constants"

export default function CurrencyControl() {
  const state = useGlobalState()
  const id = state?.currentStoreDetails?.id ?? "" //Hide from stores that aren't part of our KOS testing.
  const { currentCurrency, setCurrency, currencyCodes } = usePricing()

  const [open, setOpened] = useState(true)
  if (
    id !== KOS_COMPANY_ID &&
    id !== "KEz5uurMaOd0Wa3ctlGnUqpqH6P2" &&
    id !== "SsVdsM0ogkgpbzO8QLH4oSVqnYs2"
  ) {
    return null
  }

  return (
    <Dialog
      opened={open}
      onClose={() => setOpened(false)}
      withCloseButton
      size="md"
      radius="md"
      shadow="xl"
    >
      <Flex direction="column">
        <h3 style={{ fontSize: "0.9rem", fontWeight: 600 }}>
          Available Currencies:
        </h3>
        <SegmentedControl
          size="sm"
          styles={{
            label: {
              marginBottom: 0,
            },
          }}
          onChange={currency => {
            setCurrency(currency)
          }}
          value={currentCurrency}
          data={[
            { label: "🇺🇸 USD", value: currencyCodes.USD },
            { label: "🇹🇹 TTD", value: currencyCodes.TTD },
            // { label: "🇬🇧 GBP", value: currencyCodes.GBP },
            // { label: "🇪🇺 EUR", value: currencyCodes.EUR },
          ]}
        />
      </Flex>
    </Dialog>
  )
}
